import { IUserProfile } from 'app/shared/model/user-profile.model';
import { IGoal } from 'app/shared/model/goal.model';

export interface IGoalComment {
  id?: number;
  text?: string;
  commentator?: IUserProfile;
  goal?: IGoal;
}

export const defaultValue: Readonly<IGoalComment> = {};
