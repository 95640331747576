import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, getSortState, IPaginationBaseState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, reset } from './log-record.reducer';
import { ILogRecord } from 'app/shared/model/log-record.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface ILogRecordProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const LogRecord = (props: ILogRecordProps) => {
  const [paginationState, setPaginationState] = useState(getSortState(props.location, ITEMS_PER_PAGE));
  const [sorting, setSorting] = useState(false);

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const resetAll = () => {
    props.reset();
    setPaginationState({
      ...paginationState,
      activePage: 1
    });
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if (window.pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    props.reset();
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p
    });
    setSorting(true);
  };

  const { logRecordList, match, loading } = props;
  return (
    <div>
      <h2 id="log-record-heading">
        <Translate contentKey="wishApp.logRecord.home.title">Log Records</Translate>
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="wishApp.logRecord.home.createLabel">Create new Log Record</Translate>
        </Link>
      </h2>
      <div className="table-responsive">
        <InfiniteScroll
          pageStart={paginationState.activePage}
          loadMore={handleLoadMore}
          hasMore={paginationState.activePage - 1 < props.links.next}
          loader={<div className="loader">Loading ...</div>}
          threshold={0}
          initialLoad={false}
        >
          {logRecordList && logRecordList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('type')}>
                    <Translate contentKey="wishApp.logRecord.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('accessLevel')}>
                    <Translate contentKey="wishApp.logRecord.accessLevel">Access Level</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('ammountCurrent')}>
                    <Translate contentKey="wishApp.logRecord.ammountCurrent">Ammount Current</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('ammountBefore')}>
                    <Translate contentKey="wishApp.logRecord.ammountBefore">Ammount Before</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('textCurrent')}>
                    <Translate contentKey="wishApp.logRecord.textCurrent">Text Current</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('textBefore')}>
                    <Translate contentKey="wishApp.logRecord.textBefore">Text Before</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="wishApp.logRecord.loggedUser">Logged User</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="wishApp.logRecord.editedUser">Edited User</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="wishApp.logRecord.goal">Goal</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {logRecordList.map((logRecord, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${logRecord.id}`} color="link" size="sm">
                        {logRecord.id}
                      </Button>
                    </td>
                    <td>
                      <Translate contentKey={`wishApp.LogRecordType.${logRecord.type}`} />
                    </td>
                    <td>
                      <Translate contentKey={`wishApp.AccessLevelType.${logRecord.accessLevel}`} />
                    </td>
                    <td>{logRecord.ammountCurrent}</td>
                    <td>{logRecord.ammountBefore}</td>
                    <td>{logRecord.textCurrent}</td>
                    <td>{logRecord.textBefore}</td>
                    <td>
                      {logRecord.loggedUser ? (
                        <Link to={`user-profile/${logRecord.loggedUser.id}`}>{logRecord.loggedUser.fullName}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {logRecord.editedUser ? (
                        <Link to={`user-profile/${logRecord.editedUser.id}`}>{logRecord.editedUser.fullName}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{logRecord.goal ? <Link to={`goal/${logRecord.goal.id}`}>{logRecord.goal.title}</Link> : ''}</td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${logRecord.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${logRecord.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${logRecord.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="wishApp.logRecord.home.notFound">No Log Records found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = ({ logRecord }: IRootState) => ({
  logRecordList: logRecord.entities,
  loading: logRecord.loading,
  totalItems: logRecord.totalItems,
  links: logRecord.links,
  entity: logRecord.entity,
  updateSuccess: logRecord.updateSuccess
});

const mapDispatchToProps = {
  getEntities,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LogRecord);
