import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, getSortState, IPaginationBaseState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, reset } from './goal.reducer';
import { IGoal } from 'app/shared/model/goal.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IGoalProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Goal = (props: IGoalProps) => {
  const [paginationState, setPaginationState] = useState(getSortState(props.location, ITEMS_PER_PAGE));
  const [sorting, setSorting] = useState(false);

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const resetAll = () => {
    props.reset();
    setPaginationState({
      ...paginationState,
      activePage: 1
    });
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if (window.pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  const sort = p => () => {
    props.reset();
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p
    });
    setSorting(true);
  };

  const { goalList, match, loading } = props;
  return (
    <div>
      <h2 id="goal-heading">
        <Translate contentKey="wishApp.goal.home.title">Goals</Translate>
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="wishApp.goal.home.createLabel">Create new Goal</Translate>
        </Link>
      </h2>
      <div className="table-responsive">
        <InfiniteScroll
          pageStart={paginationState.activePage}
          loadMore={handleLoadMore}
          hasMore={paginationState.activePage - 1 < props.links.next}
          loader={<div className="loader">Loading ...</div>}
          threshold={0}
          initialLoad={false}
        >
          {goalList && goalList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('id')}>
                    <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('imageUrl')}>
                    <Translate contentKey="wishApp.goal.imageUrl">Image Url</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('title')}>
                    <Translate contentKey="wishApp.goal.title">Title</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('description')}>
                    <Translate contentKey="wishApp.goal.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('detailsUrl')}>
                    <Translate contentKey="wishApp.goal.detailsUrl">Details Url</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('targetAmount')}>
                    <Translate contentKey="wishApp.goal.targetAmount">Target Amount</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('status')}>
                    <Translate contentKey="wishApp.goal.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
{/*                  <th className="hand" onClick={sort('repeatable')}>
                    <Translate contentKey="wishApp.goal.repeatable">Repeatable</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={sort('fixedAmountPerPerson')}>
                    <Translate contentKey="wishApp.goal.fixedAmountPerPerson">Fixed Amount Per Person</Translate>{' '}
                    <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="wishApp.goal.originalGoal">Original Goal</Translate> <FontAwesomeIcon icon="sort" />
                  </th>*/}
                  <th>
                    <Translate contentKey="wishApp.goal.creator">Creator</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {goalList.map((goal, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${goal.id}`} color="link" size="sm">
                        {goal.id}
                      </Button>
                    </td>
                    <td>
                      <Button tag={Link} to={`${match.url}/${goal.id}`} color="link" size="sm">
                        <img src={`${goal.imageUrl}`} width="150" height="100"/>
                      </Button>
                    </td>
                    <td>{goal.title}</td>
                    <td>{goal.description}</td>
                    <td><a href={goal.detailsUrl} target="_blank" rel="noopener noreferrer"> Link </a></td>
                    <td>{goal.targetAmount}</td>
                    <td>
                      <Translate contentKey={`wishApp.GoalStatus.${goal.status}`} />
                    </td>
{/*                    <td>{goal.repeatable ? 'true' : 'false'}</td>
                    <td>{goal.fixedAmountPerPerson ? 'true' : 'false'}</td>*/}
                    <td>{goal.creatorFullName ? <Link to={`user-profile/${goal.creatorId}`}>{goal.creatorFullName}</Link> : ''}</td>
                    <td>{goal.originalGoalTitle ? <Link to={`goal/${goal.originalGoalId}`}>{goal.originalGoalTitle}</Link> : ''}</td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${goal.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${goal.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${goal.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="wishApp.goal.home.notFound">No Goals found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = ({ goal }: IRootState) => ({
  goalList: goal.entities,
  loading: goal.loading,
  totalItems: goal.totalItems,
  links: goal.links,
  entity: goal.entity,
  updateSuccess: goal.updateSuccess
});

const mapDispatchToProps = {
  getEntities,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Goal);
