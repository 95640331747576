import { INotificationConfiguration } from 'app/shared/model/notification-configuration.model';
import { IGoalComment } from 'app/shared/model/goal-comment.model';
import { IWish } from 'app/shared/model/wish.model';
import { IGoal } from 'app/shared/model/goal.model';
import { IUserTag } from 'app/shared/model/user-tag.model';

export interface IUserProfile {
  id?: number;
  money?: number;
  avatarUrl?: string;
  fullName?: string;
  userEmail?: string;
  userId?: number;
  notificationConfigurations?: INotificationConfiguration[];
  comments?: IGoalComment[];
  wishes?: IWish[];
  goals?: IGoal[];
  linkedTags?: IUserTag[];
}

export const defaultValue: Readonly<IUserProfile> = {};
