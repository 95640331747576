import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import GoalTag from './goal-tag';
import GoalTagDetail from './goal-tag-detail';
import GoalTagUpdate from './goal-tag-update';
import GoalTagDeleteDialog from './goal-tag-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={GoalTagDeleteDialog} />
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={GoalTagUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={GoalTagUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={GoalTagDetail} />
      <ErrorBoundaryRoute path={match.url} component={GoalTag} />
    </Switch>
  </>
);

export default Routes;
