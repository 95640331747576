import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IUserProfile } from 'app/shared/model/user-profile.model';
import { getEntities as getUserProfiles } from 'app/entities/user-profile/user-profile.reducer';
import { getEntity, updateEntity, createEntity, reset } from './notification-configuration.reducer';
import { INotificationConfiguration } from 'app/shared/model/notification-configuration.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface INotificationConfigurationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const NotificationConfigurationUpdate = (props: INotificationConfigurationUpdateProps) => {
  const [userId, setUserId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { notificationConfigurationEntity, userProfiles, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/notification-configuration' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getUserProfiles();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...notificationConfigurationEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="wishApp.notificationConfiguration.home.createOrEditLabel">
            <Translate contentKey="wishApp.notificationConfiguration.home.createOrEditLabel">
              Create or edit a NotificationConfiguration
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : notificationConfigurationEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="notification-configuration-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="notification-configuration-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="modeLabel" for="notification-configuration-mode">
                  <Translate contentKey="wishApp.notificationConfiguration.mode">Mode</Translate>
                </Label>
                <AvInput
                  id="notification-configuration-mode"
                  type="select"
                  className="form-control"
                  name="mode"
                  value={(!isNew && notificationConfigurationEntity.mode) || 'DAILY_BULLETIN'}
                >
                  <option value="DAILY_BULLETIN">{translate('wishApp.NotificationMode.DAILY_BULLETIN')}</option>
                  <option value="INSTANT_NOTIFICATION">{translate('wishApp.NotificationMode.INSTANT_NOTIFICATION')}</option>
                  <option value="NO_NOTIFICATION">{translate('wishApp.NotificationMode.NO_NOTIFICATION')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="eventLabel" for="notification-configuration-event">
                  <Translate contentKey="wishApp.notificationConfiguration.event">Event</Translate>
                </Label>
                <AvInput
                  id="notification-configuration-event"
                  type="select"
                  className="form-control"
                  name="event"
                  value={(!isNew && notificationConfigurationEntity.event) || 'MONEY_RECEIVED'}
                >
                  <option value="MONEY_RECEIVED">{translate('wishApp.NotificationEvent.MONEY_RECEIVED')}</option>
                  <option value="GOAL_CREATED">{translate('wishApp.NotificationEvent.GOAL_CREATED')}</option>
                  <option value="GOAL_STATUS_CHANGED">{translate('wishApp.NotificationEvent.GOAL_STATUS_CHANGED')}</option>
                  <option value="COMMENT_POSTED">{translate('wishApp.NotificationEvent.COMMENT_POSTED')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="notification-configuration-user">
                  <Translate contentKey="wishApp.notificationConfiguration.user">User</Translate>
                </Label>
                <AvInput id="notification-configuration-user" type="select" className="form-control" name="user">
                  <option value="" key="0" />
                  {userProfiles
                    ? userProfiles.map((otherEntity, index) => (
                        <option value={index} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/notification-configuration" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  userProfiles: storeState.userProfile.entities,
  notificationConfigurationEntity: storeState.notificationConfiguration.entity,
  loading: storeState.notificationConfiguration.loading,
  updating: storeState.notificationConfiguration.updating,
  updateSuccess: storeState.notificationConfiguration.updateSuccess
});

const mapDispatchToProps = {
  getUserProfiles,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationConfigurationUpdate);
