import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Goal from './goal';
import Wish from './wish';
import GoalComment from './goal-comment';
import LogRecord from './log-record';
import NotificationConfiguration from './notification-configuration';
import UserProfile from './user-profile';
import GoalTag from './goal-tag';
import UserTag from './user-tag';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}goal`} component={Goal} />
      <ErrorBoundaryRoute path={`${match.url}wish`} component={Wish} />
      <ErrorBoundaryRoute path={`${match.url}goal-comment`} component={GoalComment} />
      <ErrorBoundaryRoute path={`${match.url}log-record`} component={LogRecord} />
      <ErrorBoundaryRoute path={`${match.url}notification-configuration`} component={NotificationConfiguration} />
      <ErrorBoundaryRoute path={`${match.url}user-profile`} component={UserProfile} />
      <ErrorBoundaryRoute path={`${match.url}goal-tag`} component={GoalTag} />
      <ErrorBoundaryRoute path={`${match.url}user-tag`} component={UserTag} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
