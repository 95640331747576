import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Button, Col, Label, Row, UncontrolledTooltip} from 'reactstrap';
import {Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {
  createWishForGoal, getEntity, getWishForGoal,
  startGoal, stopGoal, finishGoal, reopenGoal, deactivateGoal, reactivateGoal, updateWishForGoal
} from './goal.reducer';
import {AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {AUTHORITIES} from 'app/config/constants';
import {hasAnyAuthority} from 'app/shared/auth/private-route';
import WishListForGoal from "app/entities/wish/wish-list-for-goal";
import {GoalStatus} from "app/shared/model/enumerations/goal-status.model";

export interface IWishForGoalProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string, url: string }> {}

export const WishForGoal = (props: IWishForGoalProps) => {
  useEffect(() => {
    props.getWishForGoal(props.match.params.id)
    props.getEntity(props.match.params.id);

  }, []);

  const { goalEntity, wishEntity, updating, loading, loadingWish, isAdmin } = props;

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const newWishEntity = {
        ...wishEntity,
        ...values
      };

      if (!newWishEntity.id) {
        props.createWishForGoal(newWishEntity);
      } else {
        props.updateWishForGoal(newWishEntity);
      }
    }
  };

  const handleClose = () => {
    props.history.push('/goal' + props.location.search);
  };

  const getWishEntity =() =>{
    return wishEntity
  }

  const goalControlPanel = () =>{
    if(!isAdmin) return null
    let controls
    if (goalEntity.status === GoalStatus.NEW || goalEntity.status === GoalStatus.ACTIVE){
      controls = (
        <div>
          <Button id="jhi-start-goal" color="danger" onClick={() => props.startGoal(goalEntity.id)}>
            <FontAwesomeIcon icon="trash" />
            &nbsp;
            <Translate contentKey="wishApp.goal.action.start">Start</Translate>
          </Button>
          <Button id="jhi-start-goal" color="danger" onClick={() => props.deactivateGoal(goalEntity.id)}>
            <FontAwesomeIcon icon="trash" />
            &nbsp;
            <Translate contentKey="wishApp.goal.action.deactivate">Deactivate</Translate>
          </Button>
        </div>
      )
    } else if (goalEntity.status === GoalStatus.STARTED){
      controls = (
        <div>
          <Button id="jhi-start-goal" color="danger" onClick={() => props.stopGoal(goalEntity.id)}>
            <FontAwesomeIcon icon="trash" />
            &nbsp;
            <Translate contentKey="wishApp.goal.action.stop">Stop</Translate>
          </Button>
          <Button id="jhi-start-goal" color="danger" onClick={() => props.finishGoal(goalEntity.id)}>
            <FontAwesomeIcon icon="trash" />
            &nbsp;
            <Translate contentKey="wishApp.goal.action.finish">Finish</Translate>
          </Button>
        </div>
      )
    } else if (goalEntity.status === GoalStatus.FINISHED) {
      controls = (
        <div>
          <Button id="jhi-start-goal" color="danger" onClick={() => props.reopenGoal(goalEntity.id)}>
            <FontAwesomeIcon icon="trash"/>
            &nbsp;
            <Translate contentKey="wishApp.goal.action.reopen">Reopen</Translate>
          </Button>
        </div>
      )
    } else if (goalEntity.status === GoalStatus.DEACTIVATED) {
      controls = (
        <div>
          <Button id="jhi-start-goal" color="danger" onClick={() => props.reactivateGoal(goalEntity.id)}>
            <FontAwesomeIcon icon="trash"/>
            &nbsp;
            <Translate contentKey="wishApp.goal.action.reactivate">Reactivate</Translate>
          </Button>
        </div>
      )
    }

    return controls
  }

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  return (
    <div>
      <Row>
        <Col md="5">
          <a href={goalEntity.detailsUrl} target="_blank" rel="noopener noreferrer">
            <img src={goalEntity.imageUrl} width="450" height="300" alt="Goal picture"/>
          </a>
        </Col>
        <Col md="7">
            Wish ID: {wishEntity.id}
            <h2>
              <Translate contentKey="wishApp.goal.detail.title">Goal</Translate> [<b>{goalEntity.id}</b>]
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="title">
                  <Translate contentKey="wishApp.goal.title">Title</Translate>
                </span>
              </dt>
              <dd>{goalEntity.title}</dd>
              <dt>
                <span id="description">
                  <Translate contentKey="wishApp.goal.description">Description</Translate>
                </span>
              </dt>
              <dd>{goalEntity.description}</dd>
              <dt>
                <span id="targetAmount">
                  <Translate contentKey="wishApp.goal.targetAmount">Target Amount</Translate>
                </span>
              </dt>
              <dd>{goalEntity.targetAmount}</dd>
              <dt>
                <span id="status">
                  <Translate contentKey="wishApp.goal.status">Status</Translate>
                </span>
              </dt>
              <dd>{goalEntity.status}</dd>
              <dt>
                <span id="repeatable">
                  <Translate contentKey="wishApp.goal.repeatable">Repeatable</Translate>
                </span>
              </dt>
              <dd>{goalEntity.repeatable ? 'true' : 'false'}</dd>
              <dt>
                <span id="fixedAmountPerPerson">
                  <Translate contentKey="wishApp.goal.fixedAmountPerPerson">Fixed Amount Per Person</Translate>
                </span>
              </dt>
              <dd>{goalEntity.fixedAmountPerPerson ? 'true' : 'false'}</dd>
              <dt>
                <Translate contentKey="wishApp.goal.linkedTag">Linked Tag</Translate>
              </dt>
              <dd>
                {goalEntity.linkedTags
                  ? goalEntity.linkedTags.map((val, i) => (
                    <span key={val.id}>
                        <a>{val.label}</a>
                      {i === goalEntity.linkedTags.length - 1 ? '' : ', '}
                      </span>
                  ))
                  : null}
              </dd>
              <dt>
                <Translate contentKey="wishApp.goal.creator">Creator</Translate>
              </dt>
              <dd>{goalEntity.creatorFullName ? goalEntity.creatorFullName: ''}</dd>
              { isAdmin ? (
                <div>
                  <dt>
                  <span id="targetAmount">
                    <Translate contentKey="wishApp.goal.totalCurrentAmountCollected">Total Current Amount Collected</Translate>
                  </span>
                  </dt>
                  <dd>{goalEntity.totalCurrentAmountCollected}</dd>
                  <dt>
                  <span id="targetAmount">
                  <Translate contentKey="wishApp.goal.totalMaxAmountCollected">Total Max Amount Collected</Translate>
                  </span>
                  </dt>
                  <dd>{goalEntity.totalMaxAmountCollected}</dd>
                  {goalControlPanel()}

                </div>
              ): null
              }


            </dl>
              {(loading || loadingWish) ? (
                <p>Loading...</p>
              ) : (
                <AvForm model={wishEntity} onSubmit={saveEntity}>

                  <AvGroup>
                    <Label for="wish-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    {wishEntity.maxAmount}
                  </AvGroup>

                  <AvGroup>
                    <Label id="maxAmountLabel" for="wish-maxAmount">
                      <Translate contentKey="wishApp.wish.maxAmount">Max Amount</Translate>
                    </Label>
                    <AvField id="wish-maxAmount" type="string" className="form-control" name="maxAmount" hop={wishEntity} />
                    <UncontrolledTooltip target="maxAmountLabel">
                      <Translate contentKey="wishApp.wish.help.maxAmount" />
                    </UncontrolledTooltip>
                  </AvGroup>
                  <AvGroup check>
                    <Label id="piblicLabel">
                      <AvInput id="wish-piblic" type="checkbox" className="form-check-input" name="piblic" />
                      <Translate contentKey="wishApp.wish.piblic">Piblic</Translate>
                    </Label>
                  </AvGroup>
                  <AvGroup check>
                    <Label id="repeatableLabel">
                      <AvInput id="wish-repeatable" type="checkbox" className="form-check-input" name="repeatable" />
                      <Translate contentKey="wishApp.wish.repeatable">Repeatable</Translate>
                    </Label>
                  </AvGroup>
                  <AvGroup>
                    <Label id="remarkLabel" for="wish-remark">
                      <Translate contentKey="wishApp.wish.remark">Remark</Translate>
                    </Label>
                    <AvField id="wish-remark" type="text" name="remark" />
                  </AvGroup>

                  <Button tag={Link} id="cancel-save" to="/goal" replace color="info">
                    <FontAwesomeIcon icon="arrow-left" />
                    &nbsp;
                    <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.back">Back</Translate>
                      </span>
                  </Button>
                  &nbsp;
                  <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                    <FontAwesomeIcon icon="save" />
                    &nbsp;
                    <Translate contentKey="entity.action.save">Save</Translate>
                  </Button>
                </AvForm>
              )}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          <WishListForGoal location={props.location} match={props.match} history={props.history} goalId={props.match.params.id} />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ goal , wish, authentication}: IRootState) => ({
  goalEntity: goal.entity,
  wishEntity: goal.wish,
  updating: goal.updating,
  loading: goal.loading,
  loadingWish: goal.loadingWish,
  updateSuccess: goal.updateSuccess,
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
});

const mapDispatchToProps = {
  getEntity, getWishForGoal, createWishForGoal, updateWishForGoal,
  startGoal, stopGoal, finishGoal, reopenGoal, deactivateGoal, reactivateGoal
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WishForGoal);
