import { IUserProfile } from 'app/shared/model/user-profile.model';
import { IGoal } from 'app/shared/model/goal.model';
import { LogRecordType } from 'app/shared/model/enumerations/log-record-type.model';
import { AccessLevelType } from 'app/shared/model/enumerations/access-level-type.model';

export interface ILogRecord {
  id?: number;
  type?: LogRecordType;
  accessLevel?: AccessLevelType;
  ammountCurrent?: number;
  ammountBefore?: number;
  textCurrent?: number;
  textBefore?: number;
  loggedUser?: IUserProfile;
  editedUser?: IUserProfile;
  goal?: IGoal;
}

export const defaultValue: Readonly<ILogRecord> = {};
