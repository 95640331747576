import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './goal.reducer';
import { IGoal } from 'app/shared/model/goal.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IGoalDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const GoalDetail = (props: IGoalDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { goalEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="wishApp.goal.detail.title">Goal</Translate> [<b>{goalEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="title">
              <Translate contentKey="wishApp.goal.title">Title</Translate>
            </span>
          </dt>
          <dd>{goalEntity.title}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="wishApp.goal.description">Description</Translate>
            </span>
          </dt>
          <dd>{goalEntity.description}</dd>
          <dt>
            <span id="imageUrl">
              <Translate contentKey="wishApp.goal.imageUrl">Image Url</Translate>
            </span>
          </dt>
          <dd>{goalEntity.imageUrl}</dd>
          <dt>
            <span id="detailsUrl">
              <Translate contentKey="wishApp.goal.detailsUrl">Details Url</Translate>
            </span>
          </dt>
          <dd>{goalEntity.detailsUrl}</dd>
          <dt>
            <span id="targetAmount">
              <Translate contentKey="wishApp.goal.targetAmount">Target Amount</Translate>
            </span>
          </dt>
          <dd>{goalEntity.targetAmount}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="wishApp.goal.status">Status</Translate>
            </span>
          </dt>
          <dd>{goalEntity.status}</dd>
          <dt>
            <span id="repeatable">
              <Translate contentKey="wishApp.goal.repeatable">Repeatable</Translate>
            </span>
          </dt>
          <dd>{goalEntity.repeatable ? 'true' : 'false'}</dd>
          <dt>
            <span id="fixedAmountPerPerson">
              <Translate contentKey="wishApp.goal.fixedAmountPerPerson">Fixed Amount Per Person</Translate>
            </span>
          </dt>
          <dd>{goalEntity.fixedAmountPerPerson ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="wishApp.goal.linkedTag">Linked Tag</Translate>
          </dt>
          <dd>
            {goalEntity.linkedTags
              ? goalEntity.linkedTags.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.label}</a>
                    {i === goalEntity.linkedTags.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="wishApp.goal.creator">Creator</Translate>
          </dt>
          <dd>{goalEntity.creatorFullName ? goalEntity.creatorFullName : ''}</dd>
          <dt>
            <Translate contentKey="wishApp.goal.originalGoal">Original Goal</Translate>
          </dt>
          <dd>{goalEntity.originalGoalTitle ? goalEntity.originalGoalTitle : ''}</dd>
        </dl>
        <Button tag={Link} to="/goal" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/goal/${goalEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ goal }: IRootState) => ({
  goalEntity: goal.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GoalDetail);
