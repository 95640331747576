import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IUserProfile } from 'app/shared/model/user-profile.model';
import { getEntities as getUserProfiles } from 'app/entities/user-profile/user-profile.reducer';
import { IGoal } from 'app/shared/model/goal.model';
import { getEntities as getGoals } from 'app/entities/goal/goal.reducer';
import { getEntity, updateEntity, createEntity, reset } from './log-record.reducer';
import { ILogRecord } from 'app/shared/model/log-record.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ILogRecordUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const LogRecordUpdate = (props: ILogRecordUpdateProps) => {
  const [loggedUserId, setLoggedUserId] = useState('0');
  const [editedUserId, setEditedUserId] = useState('0');
  const [goalId, setGoalId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { logRecordEntity, userProfiles, goals, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/log-record');
  };

  useEffect(() => {
    if (!isNew) {
      props.getEntity(props.match.params.id);
    }

    props.getUserProfiles();
    props.getGoals();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...logRecordEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="wishApp.logRecord.home.createOrEditLabel">
            <Translate contentKey="wishApp.logRecord.home.createOrEditLabel">Create or edit a LogRecord</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : logRecordEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="log-record-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="log-record-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="typeLabel" for="log-record-type">
                  <Translate contentKey="wishApp.logRecord.type">Type</Translate>
                </Label>
                <AvInput
                  id="log-record-type"
                  type="select"
                  className="form-control"
                  name="type"
                  value={(!isNew && logRecordEntity.type) || 'GOAL_CREATE'}
                >
                  <option value="GOAL_CREATE">{translate('wishApp.LogRecordType.GOAL_CREATE')}</option>
                  <option value="GOAL_START">{translate('wishApp.LogRecordType.GOAL_START')}</option>
                  <option value="GOAL_STOP">{translate('wishApp.LogRecordType.GOAL_STOP')}</option>
                  <option value="GOAL_FINISH">{translate('wishApp.LogRecordType.GOAL_FINISH')}</option>
                  <option value="GOAL_REOPEN">{translate('wishApp.LogRecordType.GOAL_REOPEN')}</option>
                  <option value="GOAL_DEACTIVATE">{translate('wishApp.LogRecordType.GOAL_DEACTIVATE')}</option>
                  <option value="GOAL_ACTIVATE">{translate('wishApp.LogRecordType.GOAL_ACTIVATE')}</option>
                  <option value="GOAL_EDIT">{translate('wishApp.LogRecordType.GOAL_EDIT')}</option>
                  <option value="COMMENT_CREATE">{translate('wishApp.LogRecordType.COMMENT_CREATE')}</option>
                  <option value="COMMENT_DELETE">{translate('wishApp.LogRecordType.COMMENT_DELETE')}</option>
                  <option value="WISH_CREATE">{translate('wishApp.LogRecordType.WISH_CREATE')}</option>
                  <option value="WISH_EDIT">{translate('wishApp.LogRecordType.WISH_EDIT')}</option>
                  <option value="WISH_DEACTIVATE">{translate('wishApp.LogRecordType.WISH_DEACTIVATE')}</option>
                  <option value="WISH_GIVEUP">{translate('wishApp.LogRecordType.WISH_GIVEUP')}</option>
                  <option value="USER_LOGGING">{translate('wishApp.LogRecordType.USER_LOGGING')}</option>
                  <option value="USER_EXIT">{translate('wishApp.LogRecordType.USER_EXIT')}</option>
                  <option value="USER_CREATE">{translate('wishApp.LogRecordType.USER_CREATE')}</option>
                  <option value="USER_MONEY_CHANGE">{translate('wishApp.LogRecordType.USER_MONEY_CHANGE')}</option>
                  <option value="USER_EDIT">{translate('wishApp.LogRecordType.USER_EDIT')}</option>
                  <option value="USER_DELETE">{translate('wishApp.LogRecordType.USER_DELETE')}</option>
                  <option value="PROCESS_BULLETIN_START">{translate('wishApp.LogRecordType.PROCESS_BULLETIN_START')}</option>
                  <option value="PROCESS_BULLETIN_FINISH">{translate('wishApp.LogRecordType.PROCESS_BULLETIN_FINISH')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="accessLevelLabel" for="log-record-accessLevel">
                  <Translate contentKey="wishApp.logRecord.accessLevel">Access Level</Translate>
                </Label>
                <AvInput
                  id="log-record-accessLevel"
                  type="select"
                  className="form-control"
                  name="accessLevel"
                  value={(!isNew && logRecordEntity.accessLevel) || 'NOT_SPECIFIED'}
                >
                  <option value="NOT_SPECIFIED">{translate('wishApp.AccessLevelType.NOT_SPECIFIED')}</option>
                  <option value="PRIVATE">{translate('wishApp.AccessLevelType.PRIVATE')}</option>
                  <option value="PUBLIC">{translate('wishApp.AccessLevelType.PUBLIC')}</option>
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label id="ammountCurrentLabel" for="log-record-ammountCurrent">
                  <Translate contentKey="wishApp.logRecord.ammountCurrent">Ammount Current</Translate>
                </Label>
                <AvField id="log-record-ammountCurrent" type="string" className="form-control" name="ammountCurrent" />
              </AvGroup>
              <AvGroup>
                <Label id="ammountBeforeLabel" for="log-record-ammountBefore">
                  <Translate contentKey="wishApp.logRecord.ammountBefore">Ammount Before</Translate>
                </Label>
                <AvField id="log-record-ammountBefore" type="string" className="form-control" name="ammountBefore" />
              </AvGroup>
              <AvGroup>
                <Label id="textCurrentLabel" for="log-record-textCurrent">
                  <Translate contentKey="wishApp.logRecord.textCurrent">Text Current</Translate>
                </Label>
                <AvField id="log-record-textCurrent" type="string" className="form-control" name="textCurrent" />
              </AvGroup>
              <AvGroup>
                <Label id="textBeforeLabel" for="log-record-textBefore">
                  <Translate contentKey="wishApp.logRecord.textBefore">Text Before</Translate>
                </Label>
                <AvField id="log-record-textBefore" type="string" className="form-control" name="textBefore" />
              </AvGroup>
              <AvGroup>
                <Label for="log-record-loggedUser">
                  <Translate contentKey="wishApp.logRecord.loggedUser">Logged User</Translate>
                </Label>
                <AvInput id="log-record-loggedUser" type="select" className="form-control" name="loggedUser.id">
                  <option value="" key="0" />
                  {userProfiles
                    ? userProfiles.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.fullName}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="log-record-editedUser">
                  <Translate contentKey="wishApp.logRecord.editedUser">Edited User</Translate>
                </Label>
                <AvInput id="log-record-editedUser" type="select" className="form-control" name="editedUser.id">
                  <option value="" key="0" />
                  {userProfiles
                    ? userProfiles.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.fullName}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="log-record-goal">
                  <Translate contentKey="wishApp.logRecord.goal">Goal</Translate>
                </Label>
                <AvInput id="log-record-goal" type="select" className="form-control" name="goal.id">
                  <option value="" key="0" />
                  {goals
                    ? goals.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.title}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/log-record" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  userProfiles: storeState.userProfile.entities,
  goals: storeState.goal.entities,
  logRecordEntity: storeState.logRecord.entity,
  loading: storeState.logRecord.loading,
  updating: storeState.logRecord.updating,
  updateSuccess: storeState.logRecord.updateSuccess
});

const mapDispatchToProps = {
  getUserProfiles,
  getGoals,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LogRecordUpdate);
