import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {getEntities, getWishesForGoal} from './wish.reducer';
import { IWish } from 'app/shared/model/wish.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface IWishListForGoalProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {goalId: string}

export const WishListForGoal = (props: IWishListForGoalProps) => {
  const [paginationState, setPaginationState] = useState(getSortState(props.location, ITEMS_PER_PAGE));

  const getAllEntities = () => {
    props.getWishesForGoal(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`, props.goalId);
  };

  const sortEntities = () => {
    getAllEntities();
    props.history.push(
      `${props.location.pathname}?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`
    );
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  const { wishList, match, loading, totalItems } = props;
  return (
    <div>

      <div className="table-responsive table-sm">
        {wishList && wishList.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('maxAmount')}>
                <Translate contentKey="wishApp.wish.maxAmount">Max Amount</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('currentAmount')}>
                <Translate contentKey="wishApp.wish.currentAmount">Current Amount</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('actualAmount')}>
                <Translate contentKey="wishApp.wish.actualAmount">Actual Amount</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('piblic')}>
                <Translate contentKey="wishApp.wish.piblic">Piblic</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('repeatable')}>
                <Translate contentKey="wishApp.wish.repeatable">Repeatable</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('remark')}>
                <Translate contentKey="wishApp.wish.remark">Remark</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th>
                <Translate contentKey="wishApp.wish.user">User</Translate> <FontAwesomeIcon icon="sort" />
              </th>
              <th />
            </tr>
            </thead>
            <tbody>
            {wishList.map((wish, i) => (
              <tr key={`entity-${i}`}>
                <td>
                  <Button tag={Link} to={`/wish/${wish.id}`} color="link" size="sm">
                    {wish.id}
                  </Button>
                </td>
                <td>{wish.maxAmount}</td>
                <td>{wish.currentAmount}</td>
                <td>{wish.actualAmount}</td>
                <td>{wish.piblic ? 'true' : 'false'}</td>
                <td>{wish.repeatable ? 'true' : 'false'}</td>
                <td>{wish.remark}</td>
                <td>{wish.user ? <Link to={`user-profile/${wish.user.id}`}>{wish.user.fullName}</Link> : ''}</td>
              </tr>
            ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="wishApp.wish.home.notFound">No Wishes found</Translate>
            </div>
          )
        )}
      </div>
      <div className={wishList && wishList.length > 0 ? '' : 'd-none'}>
        <Row className="justify-content-center">
          <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
        </Row>
        <Row className="justify-content-center">
          <JhiPagination
            activePage={paginationState.activePage}
            onSelect={handlePagination}
            maxButtons={5}
            itemsPerPage={paginationState.itemsPerPage}
            totalItems={props.totalItems}
          />
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = ({ wish }: IRootState) => ({
  wishList: wish.entities,
  loading: wish.loading,
  totalItems: wish.totalItems
});

const mapDispatchToProps = {
  getWishesForGoal
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WishListForGoal);
