import { IUserProfile } from 'app/shared/model/user-profile.model';
import { NotificationMode } from 'app/shared/model/enumerations/notification-mode.model';
import { NotificationEvent } from 'app/shared/model/enumerations/notification-event.model';

export interface INotificationConfiguration {
  id?: number;
  mode?: NotificationMode;
  event?: NotificationEvent;
  user?: IUserProfile;
}

export const defaultValue: Readonly<INotificationConfiguration> = {};
