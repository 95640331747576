import { IUserProfile } from 'app/shared/model/user-profile.model';
import { IGoal } from 'app/shared/model/goal.model';
import { WishStatus } from 'app/shared/model/enumerations/wish-status.model';

export interface IWish {
  id?: number;
  maxAmount?: number;
  currentAmount?: number;
  actualAmount?: number;
  piblic?: boolean;
  repeatable?: boolean;
  status?: WishStatus;
  remark?: string;
  user?: IUserProfile;
  goal?: IGoal;
}

export const defaultValue: Readonly<IWish> = {
  piblic: false,
  repeatable: false
};
