import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UserTag from './user-tag';
import UserTagDetail from './user-tag-detail';
import UserTagUpdate from './user-tag-update';
import UserTagDeleteDialog from './user-tag-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={UserTagDeleteDialog} />
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UserTagUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UserTagUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UserTagDetail} />
      <ErrorBoundaryRoute path={match.url} component={UserTag} />
    </Switch>
  </>
);

export default Routes;
