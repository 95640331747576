import axios from 'axios';
import {
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IGoal, defaultValue } from 'app/shared/model/goal.model';
import { IWish, defaultValue as wishDefaultValue } from 'app/shared/model/wish.model';
import { IPayload } from 'react-jhipster/src/type/redux-action.type';

export const ACTION_TYPES = {
  FETCH_GOAL_LIST: 'goal/FETCH_GOAL_LIST',
  FETCH_GOAL: 'goal/FETCH_GOAL',
  FETCH_WISH_FOR_GOAL: 'goal/FETCH_WISH_FOR_GOAL',
  CREATE_GOAL: 'goal/CREATE_GOAL',
  UPDATE_GOAL: 'goal/UPDATE_GOAL',
  CREATE_WISH_FOR_GOAL: 'goal/CREATE_WISH_FOR_GOAL',
  UPDATE_WISH_FOR_GOAL: 'goal/UPDATE_WISH_FOR_GOAL',
  DELETE_GOAL: 'goal/DELETE_GOAL',
  RESET: 'goal/RESET'
};

const initialState = {
  loading: false,
  loadingWish: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IGoal>,
  entity: defaultValue,
  wish: wishDefaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type GoalState = Readonly<typeof initialState>;

// Reducer

export default (state: GoalState = initialState, action): GoalState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_GOAL_LIST):
    case REQUEST(ACTION_TYPES.FETCH_GOAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.FETCH_WISH_FOR_GOAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loadingWish: true
      };
    case REQUEST(ACTION_TYPES.CREATE_GOAL):
    case REQUEST(ACTION_TYPES.UPDATE_GOAL):
    case REQUEST(ACTION_TYPES.CREATE_WISH_FOR_GOAL):
    case REQUEST(ACTION_TYPES.UPDATE_WISH_FOR_GOAL):
    case REQUEST(ACTION_TYPES.DELETE_GOAL):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_GOAL_LIST):
    case FAILURE(ACTION_TYPES.FETCH_GOAL):
    case FAILURE(ACTION_TYPES.FETCH_WISH_FOR_GOAL):
    case FAILURE(ACTION_TYPES.CREATE_GOAL):
    case FAILURE(ACTION_TYPES.UPDATE_GOAL):
    case FAILURE(ACTION_TYPES.CREATE_WISH_FOR_GOAL):
    case FAILURE(ACTION_TYPES.UPDATE_WISH_FOR_GOAL):
    case FAILURE(ACTION_TYPES.DELETE_GOAL):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_GOAL_LIST): {
      const links = parseHeaderForLinks(action.payload.headers.link);

      return {
        ...state,
        loading: false,
        links,
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_GOAL):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_WISH_FOR_GOAL):
      return {
        ...state,
        loadingWish: false,
        /*  todo remove loading manipulation here and on request */
        wish: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_GOAL):
    case SUCCESS(ACTION_TYPES.UPDATE_GOAL):
    case SUCCESS(ACTION_TYPES.CREATE_WISH_FOR_GOAL):
    case SUCCESS(ACTION_TYPES.UPDATE_WISH_FOR_GOAL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_GOAL):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/goals';
const wishApiUrl = 'api/wishes';

// Actions

export const getEntities: ICrudGetAllAction<IGoal> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_GOAL_LIST,
    payload: axios.get<IGoal>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IGoal> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_GOAL,
    payload: axios.get<IGoal>(requestUrl)
  };
};

export const getWishForGoal: ICrudGetAction<IWish> = goalId => {
  const requestUrl = `${apiUrl}/${goalId}/wish`;
  return {
    type: ACTION_TYPES.FETCH_WISH_FOR_GOAL,
    payload: axios.get<IWish>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IGoal> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_GOAL,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IGoal> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_GOAL,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const createWishForGoal: ICrudPutAction<IWish> = wishEntity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_WISH_FOR_GOAL,
    payload: axios.post(wishApiUrl, cleanEntity(wishEntity))
  });
  return result;
};

export const updateWishForGoal: ICrudPutAction<IWish> = wishEntity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_WISH_FOR_GOAL,
    payload: axios.put(wishApiUrl, cleanEntity(wishEntity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IGoal> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_GOAL,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const startGoal: ICrudGetAction<IGoal> = id => {
  const requestUrl = `${apiUrl}/${id}/start`;
  return {
    type: ACTION_TYPES.FETCH_GOAL,
    payload: axios.put<IGoal>(requestUrl)
  };
};

export const stopGoal: ICrudGetAction<IGoal> = id => {
  const requestUrl = `${apiUrl}/${id}/stop`;
  return {
    type: ACTION_TYPES.FETCH_GOAL,
    payload: axios.put<IGoal>(requestUrl)
  };
};

export const finishGoal: ICrudGetAction<IGoal> = id => {
  const requestUrl = `${apiUrl}/${id}/finish`;
  return {
    type: ACTION_TYPES.FETCH_GOAL,
    payload: axios.put<IGoal>(requestUrl)
  };
};

export const reopenGoal: ICrudGetAction<IGoal> = id => {
  const requestUrl = `${apiUrl}/${id}/reopen`;
  return {
    type: ACTION_TYPES.FETCH_GOAL,
    payload: axios.put<IGoal>(requestUrl)
  };
};

export const deactivateGoal: ICrudGetAction<IGoal> = id => {
  const requestUrl = `${apiUrl}/${id}/deactivate`;
  return {
    type: ACTION_TYPES.FETCH_GOAL,
    payload: axios.put<IGoal>(requestUrl)
  };
};

export const reactivateGoal: ICrudGetAction<IGoal> = id => {
  const requestUrl = `${apiUrl}/${id}/reactivate`;
  return {
    type: ACTION_TYPES.FETCH_GOAL,
    payload: axios.put<IGoal>(requestUrl)
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
