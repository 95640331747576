import { IGoalComment } from 'app/shared/model/goal-comment.model';
import { IWish } from 'app/shared/model/wish.model';
import { IGoalTag } from 'app/shared/model/goal-tag.model';
import { GoalStatus } from 'app/shared/model/enumerations/goal-status.model';

export interface IGoal {
  id?: number;
  title?: string;
  description?: string;
  imageUrl?: string;
  detailsUrl?: string;
  targetAmount?: number;
  status?: GoalStatus;
  repeatable?: boolean;
  fixedAmountPerPerson?: boolean;
  comments?: IGoalComment[];
  wishes?: IWish[];
  copiys?: IGoal[];
  linkedTags?: IGoalTag[];
  creatorFullName?: string;
  creatorId?: number;
  originalGoalTitle?: string;
  originalGoalId?: number;
  totalMaxAmountCollected?: number;
  totalCurrentAmountCollected?: number;
}

export const defaultValue: Readonly<IGoal> = {
  repeatable: false,
  fixedAmountPerPerson: false
};
